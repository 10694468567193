import { render, staticRenderFns } from "./AdjustConfigForm.vue?vue&type=template&id=267ea42a&scoped=true&"
import script from "./AdjustConfigForm.vue?vue&type=script&lang=js&"
export * from "./AdjustConfigForm.vue?vue&type=script&lang=js&"
import style0 from "./AdjustConfigForm.vue?vue&type=style&index=0&id=267ea42a&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "267ea42a",
  null
  
)

export default component.exports